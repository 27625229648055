import React from "react"

import Privacy from "../components/Privacy"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
    <Layout>
    <SEO
      title="Muvu - La app que te recompensa por hacer actividad física."
      description="Muvu es la app que te premia por hacer actividad física, gana puntos y canjealos por increibles premios."
    />
    <Privacy />
  </Layout>
)

export default PrivacyPage